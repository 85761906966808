import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import debounce from '../lib/debounce';

import { Layout, TextLayout } from '../components/Layout';
import { TopLevelSearch } from '../components/Search';
import { SEO } from '../components/SEO';

const IndexPage = ({ data }) => {
  const _posts = data.allMarkdownRemark.nodes;

  let cardHeightEventFunc;
  let cardResizeEventFunc;

  useEffect(() => {
    import('../lib/card').then(setCardsImageHeight => {
      setCardsImageHeight.default();

      cardResizeEventFunc = debounce(function () {
        setCardsImageHeight.default();
      }, 300);

      window.addEventListener('resize', cardResizeEventFunc);
    });
  });

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', cardResizeEventFunc);
    };
  }, []);
  useEffect(() => {
    cardHeightEventFunc = debounce(function () {
      const a = document.querySelector('.if.navigational-card.text.box');

      if (!a) return;

      const rect = a.getBoundingClientRect();

      document.documentElement.style.setProperty('--card-height', rect.height + 'px');
      document.documentElement.style.setProperty('--card-radial-height', rect.height * 2 + 'px');
    }, 300);

    window.addEventListener('resize', cardHeightEventFunc);
  });

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', cardHeightEventFunc);
    };
  }, []);

  return (
    <Layout frontPage={true} header={true}>
      <SEO />
      <header id="content" className="ids-doc if header" style={{ overflow: 'visible' }}>
        <div className="if container">
          <h1 className="if heading large">If Design System</h1>
          <p className="if text lead" style={{ marginBottom: '3rem' }}>
            The If Design System is a resource for designers, developers and anyone creating digital solutions or
            content in If, providing a common language and visual presence.
          </p>
          <TopLevelSearch />
        </div>
      </header>
      <div className="ids-doc if block">
        <div className="if container sg documentation">
          <ul className="if shortcut-bar">
            <li className="if">
              <Link className="if" to="/components">
                <svg
                  className="if icon"
                  role="presentation"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  strokeWidth="1.5"
                  viewBox="0 0 32 32">
                  <g fill="none" style={{ stroke: 'currentColor' }} strokeMiterlimit="10">
                    <path d="m2.5 7 7 3.5 7-3.5M9.5 10.5V19" data-cap="butt" />
                    <path strokeLinecap="square" d="m16.5 7-7-3.501L2.5 7v8.5l7 3.5 7-3.5V7z" />
                    <path d="m16.5 7 7 3.5 7-3.5M23.5 10.5V19" data-cap="butt" />
                    <path strokeLinecap="square" d="m30.5 7-7-3.501L16.5 7v8.5l7 3.5 7-3.5V7z" />
                    <path d="m9.5 19 7 3.5 7-3.5M16.5 22.5V31" data-cap="butt" data-color="color-2" />
                    <path strokeLinecap="square" d="m23.5 19-7-3.501L9.5 19v8.5l7 3.5 7-3.5V19z" data-color="color-2" />
                  </g>
                </svg>
                Components
              </Link>
            </li>
            <li className="if">
              <Link className="if" to="/design/foundation/color">
                <svg
                  className="if icon"
                  role="presentation"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  strokeWidth="1.5"
                  viewBox="0 0 32 32">
                  <g
                    fill="none"
                    style={{ stroke: 'currentColor' }}
                    strokeLinecap="square"
                    strokeMiterlimit="10"
                    transform="translate(.5 .5)">
                    <path d="M1 15C1 9.9 3.5 5.4 7.4 2.7c1.4-1 3.6-1.2 4.6 0 1.3 1.7-1 4.1 0 5.5 2.7 3.3 8.4-3.2 15.1 0 4.8 2.4 4.1 8.7 3.5 11-1.8 6.3-7.6 11-14.5 11C7.8 30.1 1 23.4 1 15z" />
                    <circle cx="23" cy="14" r="3" />
                    <circle cx="7" cy="14" r="2" />
                    <circle cx="9" cy="21" r="2" />
                    <circle cx="16" cy="24" r="2" />
                  </g>
                </svg>
                Colors
              </Link>
            </li>
            <li className="if">
              <Link className="if" to="/design/foundation/icons">
                <svg
                  className="if icon"
                  role="presentation"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32">
                  <title>b love</title>
                  <g
                    className="nc-icon-wrapper"
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    strokeWidth="1.5"
                    style={{ stroke: 'currentColor' }}>
                    <path
                      data-color="color-2"
                      fill="none"
                      strokeMiterlimit="10"
                      d=" M21.041,2.992C19.582,1.481,17.146,1.728,16,3.515c-1.147-1.789-3.583-2.032-5.041-0.523c-1.278,1.323-1.278,3.467,0,4.79L15.999,13 l5.042-5.218C22.32,6.459,22.32,4.315,21.041,2.992z"
                    />
                    <circle fill="none" strokeMiterlimit="10" cx="25" cy="18" r="3" />
                    <circle fill="none" strokeMiterlimit="10" cx="7" cy="18" r="3" />
                    <path
                      fill="none"
                      strokeMiterlimit="10"
                      d="M13,30v-2.983 c0-0.71-0.372-1.363-0.983-1.725C11.044,24.717,9.358,24,7,24c-2.388,0-4.064,0.713-5.026,1.288C1.368,25.65,1,26.301,1,27.007V30 H13z"
                    />
                    <path
                      fill="none"
                      strokeMiterlimit="10"
                      d="M31,30v-2.983 c0-0.71-0.372-1.363-0.983-1.725C29.044,24.717,27.358,24,25,24c-2.388,0-4.064,0.713-5.026,1.288C19.368,25.65,19,26.301,19,27.007 V30H31z"
                    />
                  </g>
                </svg>
                Icons
              </Link>
            </li>
            <li className="if">
              <Link className="if" to="/design/foundation/typography">
                <svg
                  className="if icon"
                  role="presentation"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  strokeWidth="1.5"
                  viewBox="0 0 32 32">
                  <g fill="none" style={{ stroke: 'currentColor' }} strokeLinecap="square" strokeMiterlimit="10">
                    <path d="M3 5V1h26v4M16 1v30M11 31h10" />
                  </g>
                </svg>
                Fonts
              </Link>
            </li>
            <li className="if">
              <Link className="if" to="/design/foundation/logotype">
                <svg
                  className="if icon"
                  role="presentation"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 34 34">
                  <circle cx="17" cy="17" r="16" style={{ stroke: 'currentColor' }} strokeWidth="1.5" />
                  <path
                    style={{ stroke: 'currentColor' }}
                    strokeWidth="1.5"
                    d="M12.6 19c-2.4 2-4.267 2.8-3.2-.4.32-.96.933-2.8 1.6-4.4 1-2.4-.8-1.6-2.4 0M9.8 26.6c2.4 2.8 4.16 0 5.2-2.6.8-2 3-10 4-14.5s5.2-4.5 5.2-1.3M15.4 12.25H21"
                  />
                  <g style={{ fill: 'currentColor' }}>
                    <circle cx="12.2" cy="8.4" r="1" />
                    <circle cx="21.2" cy="20.4" r="1" />
                    <circle cx="24.5" cy="20.4" r="1" />
                    <circle cx="27.8" cy="20.4" r="1" />
                  </g>
                </svg>
                Logotype
              </Link>
            </li>
          </ul>

          <ul className="if cards navigational text two">
            <li className="if">
              <Link to="/design" className="ids-doc if navigational-card text box red">
                <span
                  style={{
                    height: '4rem',
                    width: '4rem',
                    backgroundImage:
                      'url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 stroke-width=%270.75%27 viewBox=%270 0 32 32%27%3E%3Cg fill=%27none%27 stroke=%27%23444%27 stroke-miterlimit=%2710%27%3E%3Cpath d=%27m23.5 28.5 6-6M12.5 5.5l-6 6%27 data-cap=%27butt%27 data-color=%27color-2%27/%3E%3Cpath stroke-linecap=%27square%27 d=%27M10 15 3.5 8.5l6-6L16 9M23 16l6.5 6.5 1 7-7-1L17 22%27 data-color=%27color-2%27/%3E%3Cpath stroke-linecap=%27square%27 stroke-width=%270.75%27 d=%27m2.5 22.5 20-20 6.999 7-20 20z%27/%3E%3Cpath stroke-linecap=%27square%27 d=%27m12.5 12.5 3 3M9.5 15.5l2 2M18.5 6.5l3 3M15.5 9.5l2 2M6.5 18.5l3 3%27/%3E%3C/g%3E%3C/svg%3E")',
                    backgroundSize: '4rem 4rem'
                  }}
                  className="if icon"></span>
                <span className="if title" style={{ fontSize: '1.5rem' }}>
                  Design
                </span>
                <span className="if text">Foundation, guidelines and visual identity</span>
              </Link>
            </li>
            <li className="if">
              <Link to="/develop" className="ids-doc if navigational-card text box green">
                <span
                  style={{
                    height: '4rem',
                    width: '4rem',
                    backgroundImage:
                      'url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 stroke-width=%270.75%27 viewBox=%270 0 32 32%27%3E%3Cg fill=%27none%27 stroke=%27%23331e11%27 stroke-linecap=%27square%27 stroke-miterlimit=%2710%27%3E%3Cpath d=%27M2 2h28v28H2z%27/%3E%3Cpath d=%27m8 9 7 7-7 7M16 9h8M16 23h8M20 16h4%27 data-color=%27color-2%27/%3E%3C/g%3E%3C/svg%3E")',
                    backgroundSize: '4rem 4rem'
                  }}
                  className="if icon symbol love white"></span>
                <span className="if title" style={{ fontSize: '1.5rem' }}>
                  Development guidelines
                </span>
                <span className="if text">Guidance, repository, tools and frameworks</span>
              </Link>
            </li>
          </ul>
          <h2 className="ids-doc if heading large" id="index-main-resources-tools">
            Resources & tools
          </h2>
          <ul
            className="ids-doc if navigational cards text three"
            role="menu"
            aria-labelledby="index-main-resources-tools">
            <li className="if" role="presentation">
              <Link
                to="/design/getting-started#getting-started-design-xd"
                className="ids-doc if navigational-card text box">
                <span className="if title">Adobe XD UI Kit</span>
              </Link>
            </li>
            <li className="if" role="presentation">
              <Link to="/design/introduction" className="ids-doc if navigational-card text box">
                <span className="if title">Visual Identity</span>
              </Link>
            </li>
            <li className="if" role="presentation">
              <Link to="/develop/repositories-and-tools" className="ids-doc if navigational-card text box">
                <span className="if title">Repositories and Tools</span>
              </Link>
            </li>
            <li className="if" role="presentation">
              <a
                className="ids-doc if navigational-card text box"
                rel="noopener noreferrer"
                target="_blank"
                href="https://brandportal.if.eu/login">
                <span className="if title">
                  If Brand Portal<span className="if axe sr-only">, Opens in new window</span>
                </span>
              </a>
            </li>
            <li className="if" role="presentation">
              <Link to="/changelog" className="ids-doc if navigational-card text box">
                <span className="if title">Changelog</span>
              </Link>
            </li>
            <li className="if" role="presentation">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://teams.microsoft.com/l/team/19%3a5653139ad54d4f06b340907b42f35aeb%40thread.skype/conversations?groupId=5f5dd61d-c19e-437a-9f65-721db7ef30b7&tenantId=de7e7a67-ae61-49d2-97a7-526c910ad675"
                className="ids-doc if navigational-card text box">
                <span className="if title">
                  Get in touch<span className="if axe sr-only">, Opens in new window</span>
                </span>
              </a>
            </li>
          </ul>
          <h2 className="ids-doc if heading large">News & Updates</h2>
          {(!_posts || (_posts && _posts.length === 0)) && <div className="if alert-banner">No posts found.</div>}
          {_posts && _posts.length !== 0 && (
            <ol className="if ids-doc cards articles">
              {_posts.map(post => {
                const title = post.frontmatter.title || post.fields.slug;
                const author = post.frontmatter.author;
                const link = `/blog${post.fields.slug.replace('//', '/').replace(/\/+$/, '')}`;

                return (
                  <li className="ids-doc if editorial-card" key={post.fields.slug}>
                    <div className="if content">
                      <h3 className="if title heading smallest">
                        <Link to={link} className="if">
                          {title}
                        </Link>
                      </h3>
                      <p
                        className="if preview"
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt
                        }}
                        itemProp="description"
                      />
                      <div className="if meta">
                        <small className="if author">{author}</small>
                      </div>
                    </div>
                    <span className="if image lifestyle">
                      <GatsbyImage
                        role="presentation"
                        alt=""
                        image={post.frontmatter.teaserImage.childImageSharp.gatsbyImageData}
                        className="if"
                      />
                    </span>
                  </li>
                );
              })}
            </ol>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "blog" } }, frontmatter: { hidden: { ne: true } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 4
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          tags
          author
          teaserImage {
            sourceInstanceName
            name
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.shape()
};
